import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getCertificateInvoices, getLastAssignedCertificates } from '../../../../api/accounts';
import { FETCH_CERT_PURCHASE_INVOICE } from '../../../../store/actions/actionTypes';
import { Box, Divider, Grid, GridItem, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import Loader from '../../../snippets/Loader';
import InfoTiles from '../../../common/InfoTiles';
import MinimalTable from '../../../common/MinimalTable';
import CostBreakdown from '../CostBreakdown';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import CertificateBill from '../CertificateBill';
import MoneyReceipt from '../MoneyReceipt';
import { renderDate } from '../../../../util/formatUtils';
import FormButton from '../../../common/FormButton';
import DialogueBox from '../../../common/DialogueBox';
import BillDate from '../../BillDate';

const CertRequestForNewMarketExpansion = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [certificates, setCertificates] = useState();
  const certificateBillRef = useRef();
  const certMoneyReceiptRef = useRef();

  const [modal, setModal] = useState(false);
  const [billDate, setBillDate] = useState(new Date());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const data = useSelector((state) => state.accounts.currentData);
  const { orderId, certFor } = history?.location?.state;

  const moneyReceiptData = {
    slNo: data?.orderId,
    mrDate: data?.dateSold,
    receivedFrom: data?.millName,
    amount: data?.total,
    paymentMethod: data?.paymentMethod,
    certFor,
    paymentFor: 'New Market Expansion',
    chequeNo: data?.chequeNo,
    chequeDated: data?.chequeDated,
    quantity: data?.noOfCopies,
    range: data?.certificateDetails?.[0]?.certificateNum || data?.certRange,
    certNum: data?.certificateDetails?.[0]?.certificateNum,
    total: data?.total,
    narration: data?.narration ? data?.narration : null,
  };

  const millInfo = [
    { header: 'Mill Name', value: data?.millName },
    { header: 'Membership No', value: data?.membershipNo },
    { header: 'Email', value: data?.hoInfo?.millEmail },
    { header: 'Contact', value: data?.hoInfo?.millPhoneNo },
  ];

  const columns = [
    { header: 'Serial', accessor: 'certificateNum' },
    { header: 'Remarks', accessor: 'remarks' },
    { header: 'Application Status', accessor: 'applicationStatus' },
  ];

  const fetchData = async () => {
    dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: null });
    const res1 = await getCertificateInvoices({ type: 'MARKET_EXPANSION', uid: orderId });
    if (res1.data?.status === 200) {
      dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: res1.data });
      setValue('numOfCert', res1?.data?.noOfCopies);
    }

    const res2 = await getLastAssignedCertificates();
    if (res2.data?.status === 200) {
      setCertificates(res2.data);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();

    return () => {
      setCertificates();
      dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: null });
    };
  }, []);

  const {
    //reset,
    //control,
    setValue,
    //register,
    //handleSubmit,
    //formState: { errors, isSubmitting },
  } = useForm({
    //resolver,
    defaultValues: { discount: 0, dateSold: new Date() },
  });

  const handleBillPrint = () => {
    setBillDate(data?.createdAt || new Date());
    setModal('BILL_DATE');
    onOpen();
  };

  const printCertificateBill = useReactToPrint({
    documentTitle: `BTMA-Bill-New-Market-Expansion-Certificate-${data?.orderId}-${renderDate(
      billDate,
    )}`,
    content: () => certificateBillRef.current,
  });

  const renderModal = () => {
    switch (modal) {
      //  case 'PAYMENT':
      //    return (
      //      <DialogueBox
      //        close={onClose}
      //        alertTitle="Confirm"
      //        mountControl={isOpen}
      //        performAction={confirmSubmit}
      //        actionButtonLoading={isConfirming}
      //        alertMessage="Are you sure you want to submit these information?"
      //      />
      //    );

      //  case 'DELETE':
      //    return (
      //      <DialogueBox
      //        close={onClose}
      //        onActionKeepOpen
      //        alertTitle="Confirm"
      //        mountControl={isOpen}
      //        performAction={confirmDelete}
      //        actionButtonLoading={isDeleting}
      //        alertMessage="Are you sure you want to delete this batch of certificate?"
      //      />
      //    );

      case 'BILL_DATE':
        return (
          <DialogueBox
            noCancel
            close={onClose}
            mountControl={isOpen}
            actionButtonText="Okay"
            alertTitle="Issue Date"
            performAction={printCertificateBill}>
            <BillDate setDate={setBillDate} date={billDate} />
          </DialogueBox>
        );

      default:
        return null;
    }
  };

  return data && certFor ? (
    <Box p={4}>
      <InfoTiles sectionName="Mill Information" sectionColumn={4} infoFields={millInfo} />
      <Grid templateColumns="repeat(4, 1fr)" gap={3} p={4} mt={4} bg="white">
        <GridItem colSpan={4}>
          <Text fontSize="20px" fontWeight="bold" mb={1}>
            Last Used Certificate Number
          </Text>
        </GridItem>

        <GridItem
          p={4}
          colSpan={1}
          border="1px"
          bg="secondary"
          borderRadius="4px"
          borderColor="primary.300">
          {certificates ? (
            <>
              <Text fontSize="md" color="textSecondary">
                Production-GSP
              </Text>
              <Text fontSize="xl" mt={1.5}>
                {certificates?.prodGsp || 'N/A'}
              </Text>
            </>
          ) : (
            <Loader />
          )}
        </GridItem>

        <GridItem
          p={4}
          colSpan={1}
          border="1px"
          bg="secondary"
          borderRadius="4px"
          borderColor="primary.300">
          {certificates ? (
            <>
              <Text fontSize="md" color="textSecondary">
                Production-Cash
              </Text>
              <Text fontSize="xl" mt={1.5}>
                {certificates?.prodCash || 'N/A'}
              </Text>
            </>
          ) : (
            <Loader />
          )}
        </GridItem>

        <GridItem
          p={4}
          colSpan={1}
          border="1px"
          bg="secondary"
          borderRadius="4px"
          borderColor="primary.300">
          {certificates ? (
            <>
              <Text fontSize="md" color="textSecondary">
                Processing
              </Text>
              <Text fontSize="xl" mt={1.5}>
                {certificates?.proc || 'N/A'}
              </Text>
            </>
          ) : (
            <Loader />
          )}
        </GridItem>

        <GridItem
          p={4}
          colSpan={1}
          border="1px"
          bg="secondary"
          borderRadius="4px"
          borderColor="primary.300">
          {certificates ? (
            <>
              <Text fontSize="md" color="textSecondary">
                Duty-Exemption
              </Text>
              <Text fontSize="xl" mt={1.5}>
                {certificates?.duty || 'N/A'}
              </Text>
            </>
          ) : (
            <Loader />
          )}
        </GridItem>
      </Grid>

      <HStack mt={4}>
        <Text fontSize="xl" fontWeight="bold">
          Invoice:
        </Text>
        <Text fontSize="xl" fontWeight="bold" color="red">
          <b>{data?.orderId}</b>
        </Text>
      </HStack>

      <Box mt={4}>
        <Text mb={2} fontSize="xl" fontWeight="bold">
          Certificate Information
        </Text>
        <Divider mb={2} />
        <Grid templateColumns="repeat(5, 1fr)" gap={3}>
          <GridItem
            p={4}
            as="form"
            bg="white"
            colSpan={2}
            overflowX="auto"
            borderRadius="4px"
            //onSubmit={handleSubmit(onSubmit)}
          >
            <Text fontSize="1xl" ml={3} mt={3} fontWeight="bold">
              Certificates
            </Text>
            <Box p={3}>
              <MinimalTable scrollable columns={columns} data={data?.certificateDetails} />
            </Box>
          </GridItem>

          <GridItem colSpan={3} p={4} bg="white" borderRadius="4px">
            <Box p={3} maxW="45vw" overflowX="auto">
              <CostBreakdown
                data={{
                  ...data,
                  noOfCopies: 1,
                  total: data.unitCost,
                  certFor: 'New Market Expansion',
                  range: data?.certificateDetails?.[0]?.certificateNum || 'N/A',
                }}
              />
            </Box>
            <Stack flexDir="column" alignItems="flex-end" mt={1} p={3}>
              <HStack>
                <FormButton mt={0} onClick={handleBillPrint}>
                  PRINT BILL
                </FormButton>
                <Box display="none">
                  <CertificateBill
                    title="Certificate"
                    ref={certificateBillRef}
                    data={{
                      billDate,
                      billNo: data?.orderId,
                      millName: data?.millName,
                      millAddress: data?.hoInfo?.hoName,
                    }}
                    costs={{
                      ...data,
                      noOfCopies: 1,
                      total: data.unitCost,
                      certFor: 'New Market Expansion',
                      range: data?.certificateDetails?.[0]?.certificateNum || 'N/A',
                      serial: data?.certificateDetails?.[0]?.certificateNum || 'N/A',
                    }}
                  />
                </Box>

                <ReactToPrint
                  documentTitle={`BTMA-Money-New-Market-Expansion-Certificate-${
                    data?.orderId
                  }-${renderDate(data?.dateSold)}`}
                  trigger={() => <FormButton>PRINT RECEIPT</FormButton>}
                  content={() => certMoneyReceiptRef.current}
                />

                <Box display="none">
                  <MoneyReceipt ref={certMoneyReceiptRef} data={moneyReceiptData} />
                </Box>
              </HStack>
            </Stack>
          </GridItem>
        </Grid>
      </Box>
      {renderModal()}
    </Box>
  ) : (
    <Loader />
  );
};

export default CertRequestForNewMarketExpansion;
