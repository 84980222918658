export const ROOT_URL = '/';
export const LOG_IN_URL = '/login';
export const ACCOUNT_VERIFICATION_URL = '/account-verification-otp';
export const PASSWORD_RESET_URL = '/password-reset';
export const PASSWORD_RESET_VERIFICATION_URL = '/password-reset-otp';
export const RESET_PASSWORD_URL = '/reset-password';

export const DASHBOARD_URL = '/dashboard';
export const SETTINGS_URL = '/settings';

//Membership
export const NEW_REQUEST_URL = '/membership/new-requests';
export const NEW_REQUEST_VERIFICATION_URL = '/membership/new-requests/verification';
export const UPDATE_REQUEST_URL = '/membership/update-requests';
export const UPDATE_REQUEST_VERIFICATION_URL = '/membership/update-requests/verification';
export const ACTIVE_MEMBERS = '/membership/active-members';
export const INACTIVE_MEMBERS = '/membership/inactive-members';
export const ADD_NEW_MEMBER_URL = '/membership/add-new';
export const MEMBER_INFO_URL = '/membership/active-members/details';
export const MEMBER_INFO_URL_FOR_PROVISIONAL_MEMBERS = '/membership/provisional-members/details';
export const MILL_REPRESENTATIVE_URL = '/membership/mill-representative';
export const PROVISIONAL_MEMBERS = '/membership/provisional-members';

//Certificates
export const GSP_CERT_URL = '/certificates/gsp';
export const PROCESSING_CERT_URL = '/certificates/processing';
export const CASH_CERT_URL = '/certificates/cash-incentive';
export const DUTY_CERT_URL = '/certificates/duty-exemption';
export const GSP_CERT_VERIFICATION_URL = '/certificates/gsp/verification';
export const GSP_CERT_DETAILS_URL = '/certificates/gsp/details';
export const GSP_CERT_APPLY_URL = '/certificates/gsp/apply';
export const PROCESSING_CERT_VERIFICATION_URL = '/certificates/processing/verification';
export const PROCESSING_CERT_DETAILS_URL = '/certificates/processing/details';
export const PROCESSING_CERT_APPLY_URL = '/certificates/processing/apply';
export const CASH_CERT_VERIFICATION_URL = '/certificates/cash-incentive/verification';
export const CASH_CERT_DETAILS_URL = '/certificates/cash-incentive/details';
export const CASH_CERT_APPLY_URL = '/certificates/cash-incentive/apply';
export const DUTY_CERT_VERIFICATION_URL = '/certificates/duty-exemption/verification';
export const DUTY_CERT_DETAILS_URL = '/certificates/duty-exemption/details';
export const DUTY_CERT_APPLY_URL = '/certificates/duty-exemption/apply';
export const NEW_MARKET_EXPANSION_CERT_URL = '/certificates/new-market-expansion';
export const NEW_MARKET_EXPANSION_APPLY_URL = '/certificates/new-market-expansion/apply';
export const NEW_MARKET_EXPANSION_CERT_URL_FOR_FEE_COLLECTOR =
  '/dashboard/fee-collector/new-market-expansion';
export const NEW_MARKET_EXPANSION_APPLY_URL_FOR_FEE_COLLECTOR =
  '/dashboard/fee-collector/new-market-expansion/apply';
export const NEW_MARKET_EXPANSION_DETAILS_URL = '/certificates/new-market-expansion/details';

//Accounts
export const ACCOUNTS_MEMBERSHIP_URL = '/accounts/membership';
export const ACCOUNTS_MEMBERSHIP_PAYMENTS_NEW_URL = '/accounts/membership/new-subscription';
export const ACCOUNTS_MEMBERSHIP_PAYMENTS_RENEW_URL = '/accounts/membership/renewal';
export const ACCOUNTS_MEMBERSHIP_MILL_URL = '/accounts/membership/mill';
export const ACCOUNTS_CERTIFICATE_URL = '/accounts/certificate';
export const ACCOUNTS_CERTIFICATE_PURCHASE_URL = '/accounts/certificate/purchase';
export const ACCOUNTS_CERTIFICATE_PURCHASE_URL_DUTY =
  '/accounts/certificate/duty-purchase-requests';
export const ACCOUNTS_CERTIFICATE_PURCHASE_URL_NEW_MARKET_EXPANSION =
  '/accounts/certificate/new-market-expansion-requests';

//Labour Database
export const LABOUR_DATABASE_URL = '/labour-database';
export const LABOUR_DETAILS_URL = '/labour-database/details';
export const ADD_LABOUR_URL = '/labour-database/add';

//Certificate generation
export const GSP_CERT_GEN_URL = '/certificate-generation/gsp';
export const CASH_CERT_GEN_URL = '/certificate-generation/cash-incentive';
export const PROCESSING_CERT_GEN_URL = '/certificate-generation/processing';
export const DUTY_CERT_GEN_URL = '/certificate-generation/duty-exemption';

//Reports
export const GSP_CERT_REPORT_URL = '/reports/gsp';
export const CASH_CERT_REPORT_URL = '/reports/cash-incentive';
export const PROCESSING_CERT_REPORT_URL = '/reports/processing';
export const DUTY_CERT_REPORT_URL = '/reports/duty-exemption';
export const MONEY_RECEIPT_REPORT_URL = '/reports/money-receipt';
export const MILL_INFORMATION_REPORT_URL = '/reports/mill-information';
export const NEW_MARKET_EXPANSION_REPORT_URL = '/reports/new-market-expansion';

//Fee Collector
export const FEE_COLLECTION_URL = '/dashboard/collect-fees';
export const FEE_COLLECTION_VIEW_RECEIPT = '/dashboard/view-receipts';
export const FEE_COLLECTION_VIEW_RECEIPT_CERTIFICATE_PURCHASE_URL_DUTY =
  '/dashboard/view-receipts/duty-purchase-requests';
export const FEE_COLLECTION_VIEW_RECEIPT_FOR_NEW_MARKET_EXPANSION =
  '/dashboard/view-new-market-expansion-receipts';
export const FEE_COLLECTION_VIEW_RECEIPT_CERTIFICATE_PURCHASE_URL_NEW_MARKET_EXPANSION =
  '/dashboard/view-new-market-expansion-receipts/new-market-expansion-purchase-requests';
export const FEE_COLLECTION_MILL_REPRESENTATIVE_URL = '/dashboard/view-mill-representative';
