import {
  Box,
  CloseButton,
  Divider,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineFileSearch } from 'react-icons/ai';
import { getMillCategories } from '../../../api/membership';
import { getNewMarketExpansionReport } from '../../../api/reports';
import { renderDate } from '../../../util/formatUtils';
import FormButton from '../../common/FormButton';
import SearchSection from '../../common/SearchSection';
import Loader from '../../snippets/Loader';
import FileExporter from '../commonSections/FileExporter';
import SelectReport from '../commonSections/SelectReport';

const NewMarketExpansionReportWizard = () => {
  const [mill, setMill] = useState();
  const [table, setTable] = useState();
  const [results, setResults] = useState();
  const [loader, setLoader] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [membershipType, setMembershipType] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState();

  useEffect(() => {
    document.title = 'BTMA - Reports';
  }, []);

  useEffect(() => {
    (async () => {
      const { categories } = await getMillCategories();
      if (categories) {
        const options = categories.map(({ id, category }) => ({ name: category, value: id }));
        setCategoryOptions(options);
      }
    })();
  }, []);

  useEffect(() => {
    setSelectAll(false);
    setSelectedFields([]);
  }, [table]);

  useEffect(() => {
    setResults();
  }, [selectedFields]);

  const dateFields = [
    'issue date',
    'cheque dated',
    'exp date',
    'repatriated export value certificate date',
    'sales contact date',
    'invoice date',
    'date sold',
  ];

  const basicFields = [
    { name: 'Mill Name', key: 'millInfo.millName as mill name' },
    { name: 'Certificate No', key: 'certificate_number as certificate no' },
    { name: 'Issue Date', key: 'issue_date as issue date', displayName: 'issue date' },
    { name: 'Prc No.', key: 'prc_number as prc no' },
    { name: 'Foreign Buyer Name.', key: 'foreign_buyer_name as foreign buyer name' },
    { name: 'Export Country', key: 'export_country as export country' },
    { name: 'Sales Contact No', key: 'sales_contact_number as sales contact no' },
    { name: 'Invoice No', key: 'invoice_number as invoice no' },
    { name: 'Invoice Amount', key: 'invoice_amount as invoice amount' },
    { name: 'Invoice Currency', key: 'invoice_currency as invcoice currency' },
    { name: 'Total', key: 'total as total' },
    { name: 'Narration', key: 'narration as narration' },
    { name: 'Status', key: 'status as status' },
    { name: 'Payment Status', key: 'payment_status as payment status' },
    {
      name: 'Payment Method',
      key: 'payment_method as payment method',
    },
    {
      name: 'Cheque No',
      key: 'cheque_no as cheque no',
    },
    {
      name: 'Cheque Dated',
      key: 'cheque_dated as cheque dated',
      displayName: 'cheque dated',
    },
    {
      name: 'Export Item Details',
      key: 'export_item_details as export item details',
    },
    {
      name: 'Original Export Item Quantity',
      key: 'original_export_item_quantity as original export item quantity',
    },
    {
      name: 'Original Export Item Quantity Unit',
      key: 'original_export_item_quantity_unit as original export item quantity unit',
    },
    {
      name: 'Original Export Item Value',
      key: 'original_export_item_value as original export item value',
    },
    {
      name: 'Original Export Item Value Currency',
      key: 'original_export_item_value_currency as original export item value currency',
    },
    {
      name: 'Exp Number',
      key: 'exp_number as exp number',
    },
    {
      name: 'Exp Date',
      key: 'exp_date as exp date',
      displayName: 'exp date',
    },
    {
      name: 'Exp Value',
      key: 'exp_value as exp value',
    },
    {
      name: 'Total Repatriated Foreign Export Value',
      key: 'total_repatriated_foreign_export_value as total repatriated foreign export value',
    },
    {
      name: 'Total Repatriated Foreign Export Value Currency',
      key: 'total_repatriated_foreign_export_value_currency as total repatriated foreign export value currency',
    },
    {
      name: 'Repatriated Export Value Certificate Number',
      key: 'repatriated_export_value_certificate_number as repatriated export value certificate number',
    },
    {
      name: 'Repatriated Export Value Certificate Date',
      key: 'repatriated_export_value_certificate_date as repatriated export value certificate date',
      displayName: 'repatriated export value certificate date',
    },
    {
      name: 'Net Fov Value',
      key: 'net_fov_value as net fov value',
    },
    {
      name: 'Foreign Buyer Name and Address',
      key: 'foreign_buyer_name_and_address as foreign buyer name and address',
    },
    {
      name: 'Sales Contact Date',
      key: 'sales_contact_date as sales contact date',
      displayName: 'sales contact date',
    },
    {
      name: 'Buyer Bank Name and Address',
      key: 'buyer_bank_name_and_address as buyer bank name and address',
    },
    {
      name: 'Invoice Date',
      key: 'invoice_date as invoice date',
      displayName: 'invoice date',
    },
    {
      name: 'Date Sold',
      key: 'date_sold as date sold',
      displayName: 'date sold',
    },
  ];

  const fieldsForTable = {
    BASIC: basicFields,
  };

  const getUnselectedFields = (fields) => {
    return fields?.filter((item) => !selectedFields?.some((obj) => item.key === obj.key));
  };

  const getKeys = () => {
    let keys = [];

    if (selectedFields?.length > 0) {
      for (let item of selectedFields) {
        keys.push(item.key);
      }
    } else {
      for (let item of fieldsForTable[table]) {
        keys.push(item.key);
      }
    }

    return keys;
  };

  const handleSubmit = async () => {
    setLoader(true);
    let formData = {
      categoryId,
      millId: mill?.id,
      fields: getKeys(),
      membershipType,
    };
    const res = await getNewMarketExpansionReport(formData);

    if (res.data?.status === 200) {
      const resultArray = res.data?.result;

      let availableDateFields =
        selectedFields?.length > 0
          ? dateFields?.filter((date) => selectedFields?.some((obj) => date === obj.displayName))
          : dateFields?.filter((date) =>
              fieldsForTable[table]?.some((obj) => date === obj.displayName),
            );

      console.log('availableDateFields', availableDateFields);

      let convertedArray = resultArray?.map((item) => {
        function convertFalseyValues() {
          let currItem = item;
          for (let key in currItem) {
            if (!currItem[key]) currItem[key] = 'n/a';
          }
          return currItem;
        }

        function convertDates() {
          let currItem = item;
          availableDateFields?.map((date) =>
            currItem[date] && currItem[date] !== 'n/a'
              ? (currItem[date] = renderDate(item[date], 'numeric'))
              : currItem[date],
          );
          return currItem;
        }

        return { ...convertFalseyValues(), ...convertDates() };
      });

      setResults(convertedArray);
    }
    setLoader(false);
  };

  return categoryOptions ? (
    <Box p={4}>
      <Text fontSize="2xl" fontWeight="bold">
        New Market Expansion Report Wizard
      </Text>

      <Divider mb={3} />
      <Grid templateColumns="repeat(2, 1fr)" gap={3}>
        <GridItem colSpan={1}>
          <Box p={4} bg="white" borderRadius="4px">
            <Box position="relative" p={4} border="1px solid #6B7982" borderRadius="4px">
              <Text
                px={1}
                bg="white"
                left="10px"
                top="-12px"
                fontSize="sm"
                position="absolute"
                color="textSecondary">
                Select Options
              </Text>
              <SelectReport
                label="Select Report"
                placeholder="Select an option"
                setValue={setTable}
                choices={[{ name: 'Basic Information', value: 'BASIC' }]}
              />
              {table ? (
                <>
                  <Text color="textSecondary" mt={4} mb={1.5}>
                    Select Columns
                  </Text>
                  <HStack>
                    <Box
                      p={4}
                      w="45%"
                      h="450px"
                      bg="secondary"
                      overflow="auto"
                      boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
                      {selectAll
                        ? null
                        : getUnselectedFields(fieldsForTable[table])?.map((item, index) => (
                            <Text
                              key={index}
                              cursor="pointer"
                              whiteSpace="nowrap"
                              onClick={() => setSelectedFields([...selectedFields, item])}>
                              {item?.name}
                            </Text>
                          ))}
                    </Box>
                    <VStack w="10%" h="450px" justifyContent="center">
                      <Tooltip label="Select all" aria-label="A tooltip">
                        <IconButton
                          icon={<AiOutlineDoubleRight />}
                          onClick={() => {
                            setSelectedFields(fieldsForTable[table]);
                            setSelectAll(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip label="Deselect all" aria-label="A tooltip">
                        <IconButton
                          icon={<AiOutlineDoubleLeft />}
                          onClick={() => {
                            setSelectedFields([]);
                            setSelectAll(false);
                          }}
                        />
                      </Tooltip>
                    </VStack>
                    <Box
                      p={4}
                      w="45%"
                      h="450px"
                      bg="secondary"
                      overflow="auto"
                      boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
                      {selectedFields?.map((item, index) => (
                        <Text
                          key={index}
                          cursor="pointer"
                          whiteSpace="nowrap"
                          onClick={() => {
                            setSelectAll(false);
                            setSelectedFields(
                              selectedFields?.filter((obj) => obj.key !== item.key),
                            );
                          }}>
                          {item?.name}
                        </Text>
                      ))}
                    </Box>
                  </HStack>
                </>
              ) : null}
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={1}>
          <Box p={4} bg="white" borderRadius="4px">
            <Box position="relative" p={4} border="1px solid #6B7982" borderRadius="4px">
              <Text
                px={1}
                bg="white"
                top="-12px"
                left="10px"
                fontSize="sm"
                position="absolute"
                color="textSecondary">
                Search Information
              </Text>
              <SearchSection forceSearch minimal setSelectedMill={setMill} />
              <HStack>
                <Text mt={1} color="primary.400" mb={1.5}>
                  {mill?.millName}
                </Text>
                {mill?.id ? (
                  <CloseButton
                    size="sm"
                    _focus={{ boxShadow: '0 0 0 3px #9280ff' }}
                    onClick={() => {
                      setMill();
                    }}
                  />
                ) : null}
              </HStack>
              <HStack mt={4}>
                <SelectReport
                  label="Select Category"
                  setValue={setCategoryId}
                  choices={categoryOptions}
                  placeholder="Select an option"
                />
                <SelectReport
                  label="Select Membership Type"
                  setValue={setMembershipType}
                  choices={[
                    { name: 'General', value: 'GENERAL' },
                    { name: 'Associate', value: 'ASSOCIATE' },
                  ]}
                  placeholder="Select an option"
                />
              </HStack>

              <HStack w="100%" mt={4} spacing={4}>
                <FormButton mt={0} isLoading={loader} isDisabled={!table} onClick={handleSubmit}>
                  GENERATE
                </FormButton>
                {loader ? (
                  <HStack
                    flex={1}
                    h="45px"
                    bg="secondary"
                    borderRadius="4px"
                    justifyContent="center">
                    <Spinner
                      size="md"
                      thickness="2px"
                      color="primary.700"
                      emptyColor="primary.50"
                    />
                    <Text color="primary.700">Generating Report...</Text>
                  </HStack>
                ) : results ? (
                  results?.length > 0 ? (
                    <FileExporter
                      title={`New-Market_Expansion${
                        table === 'BASIC'
                          ? '-(Basic)'
                          : table === 'OPERATION'
                          ? '-(Operation)'
                          : table === 'INSCAP'
                          ? '-(Installed Capacity)'
                          : ''
                      }`}
                      data={results}
                    />
                  ) : (
                    <Text color="textSecondary" fontSize="xl" w="100%" textAlign="center">
                      <Icon as={AiOutlineFileSearch} w={10} h={10} /> No entries found!
                    </Text>
                  )
                ) : null}
              </HStack>
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  ) : (
    <Loader />
  );
};

export default NewMarketExpansionReportWizard;
