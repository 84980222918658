import * as routerUrl from './routerUrl';

export const sideNavConfig = [
  {
    key: 0,
    title: 'Home',
    url: routerUrl.DASHBOARD_URL,
  },
  {
    title: 'Membership',
    menu: [
      {
        key: 1,
        title: 'New Requests',
        url: routerUrl.NEW_REQUEST_URL,
      },
      {
        key: 2,
        title: 'Update Requests',
        url: routerUrl.UPDATE_REQUEST_URL,
      },
      {
        key: 3,
        title: 'Active Members',
        url: routerUrl.ACTIVE_MEMBERS,
      },
      {
        key: 7,
        title: 'Provisional Members',
        url: routerUrl.PROVISIONAL_MEMBERS,
      },
      {
        key: 4,
        title: 'Inactive Members',
        url: routerUrl.INACTIVE_MEMBERS,
      },
      {
        key: 5,
        title: 'Add New Member',
        url: routerUrl.ADD_NEW_MEMBER_URL,
      },
      {
        key: 6,
        title: 'Mill Representative',
        url: routerUrl.MILL_REPRESENTATIVE_URL,
      },
    ],
  },
  {
    title: 'Certificates',
    menu: [
      {
        key: 6,
        title: 'GSP',
        url: routerUrl.GSP_CERT_URL,
      },
      {
        key: 6,
        title: 'Processing',
        url: routerUrl.PROCESSING_CERT_URL,
      },
      {
        key: 7,
        title: 'Cash Incentive',
        url: routerUrl.CASH_CERT_URL,
      },
      {
        key: 8,
        title: 'Duty Exemption',
        url: routerUrl.DUTY_CERT_URL,
      },
      {
        key: 9,
        title: 'New Market Expansion',
        url: routerUrl.NEW_MARKET_EXPANSION_CERT_URL,
      },
    ],
  },
  {
    title: 'Accounts',
    menu: [
      {
        key: 9,
        title: 'Membership',
        url: routerUrl.ACCOUNTS_MEMBERSHIP_URL,
      },
      {
        key: 10,
        title: 'Certificate',
        url: routerUrl.ACCOUNTS_CERTIFICATE_URL,
      },
    ],
  },
  {
    title: 'Certificate Generation',
    menu: [
      {
        key: 11,
        title: 'GSP',
        url: routerUrl.GSP_CERT_GEN_URL,
      },
      {
        key: 12,
        title: 'Processing',
        url: routerUrl.PROCESSING_CERT_GEN_URL,
      },
      {
        key: 13,
        title: 'Cash Incentive',
        url: routerUrl.CASH_CERT_GEN_URL,
      },
      {
        key: 14,
        title: 'Duty Exemption',
        url: routerUrl.DUTY_CERT_GEN_URL,
      },
    ],
  },
  {
    title: 'Reports',
    menu: [
      {
        key: 15,
        title: 'GSP Report',
        url: routerUrl.GSP_CERT_REPORT_URL,
      },
      {
        key: 16,
        title: 'Processing Report',
        url: routerUrl.PROCESSING_CERT_REPORT_URL,
      },
      {
        key: 17,
        title: 'Cash Incentive Report',
        url: routerUrl.CASH_CERT_REPORT_URL,
      },
      {
        key: 18,
        title: 'Duty Exemption Report',
        url: routerUrl.DUTY_CERT_REPORT_URL,
      },
      {
        key: 19,
        title: 'Money Receipt Report',
        url: routerUrl.MONEY_RECEIPT_REPORT_URL,
      },
      {
        key: 20,
        title: 'Mill Information Report',
        url: routerUrl.MILL_INFORMATION_REPORT_URL,
      },
      {
        key: 21,
        title: 'New Market Expansion Report',
        url: routerUrl.NEW_MARKET_EXPANSION_REPORT_URL,
      },
    ],
  },
  {
    key: 21,
    title: 'Labour Database',
    url: routerUrl.LABOUR_DATABASE_URL,
  },
];
