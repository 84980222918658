import axios from './axios';

// export const patchSubCost = async () => {
//   try {
//     const res = await axios.post('/reports/patchScript');
//     if (res.status === 200)
//       return {
//         data: res.data,
//         error: null,
//       };
//   } catch (error) {
//     return { data: null, error };
//   }
// };

export const getAllCertReports = async (data) => {
  try {
    const res = await axios.post(`/reports/certificate/${data?.type}`, data?.formData);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getAllMoneyReceiptReports = async (data) => {
  try {
    const res = await axios.post(`/reports/money-receipt/${data?.table}`, data?.formData);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getMillInfoReport = async (data) => {
  try {
    const res = await axios.post('/reports/mill-information', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getNewMarketExpansionReport = async (data) => {
  try {
    const res = await axios.post('/reports/new-market-expansion', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getMillArrearReport = async (data) => {
  try {
    const res = await axios.get(`/reports/mill-arrears/${data?.category}/${data?.membershipType}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getSubscriptionCostReport = async (data) => {
  try {
    const res = await axios.get(
      `/reports/mill-subsciptions/${data?.category}/${data?.membershipType}`,
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};
